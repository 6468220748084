@use '@styles/mixins/breakpoints' as breakpoints;
@use '@styles/functions' as functions;

.wrapperBenefitBar {
  height: 40px;
  width: 100%;
  font-size: 0.625rem;
  display: flex;
  background-color: var(--background-quinary-light);
  color: var(--text-primary-dark);

  &.hideOnMobile {
    @include breakpoints.down(600px) {
      display: none;
    }
  }
}

.enter {
  height: 0px;
}
.enterActive {
  height: 40px;
  transition: height 150ms ease;
}

.exit {
  height: 40px;
}
.exitActive {
  height: 0px;
  transition: height 150ms ease;
}

.rightBenefitBarWrapper {
  align-items: flex-end;
  display: flex;
  gap: functions.spacing(6);
  margin-left: auto;

  @include breakpoints.down(719px) {
    display: none;
  }

  .benefitButton {
    display: inline-block;

    @include breakpoints.up(1025px) {
      display: none;
    }
  }
}
